import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from 'styled-components'
import Accordion from '../components/Accordion';
import { Link } from 'gatsby';


const Button = styled.button`
  color: white;
  background-color: transparent;
  font-weight:500;
  height: 40px;
  width: 160px; 
  border: 1px solid transparent; 
  border-radius: 1.5px;
  font-size: 14px;
  align-self: center;
`


const FAQ = () => (
  <Layout>
    <SEO title="FAQ" />
      <section style={{width: "1024px" , "margin": "0 auto", "padding": "100px 6.5% 0px"}}>
      <div>
                    <h1 style={{marginBottom: "24px", fontSize: "32px", paddingTop: "16px"}}>Frequently asked questions</h1>
                    <h2 style={{ "fontFamily": "Yantramanav", "fontSize": "18px", "fontWeight": "400", "color": "#5e5e5e", marginBottom:"24px" }}>
                    You may also contact us via email at <a style={{ "color": "#3eb0b4", "textDecoration": "none" }} href="mailto:contact@juristerra.net">contact@juristerra.net</a> or
                     call us at <a style={{ "color": "#3eb0b4", "textDecoration": "none" }} href="tel:+19494247045">+1(949)424-7045</a>
                </h2>

                <Link to="/contact-us">
                   <Button id="on-hover-outline" style={{ color: "#0077AF", border: "1px solid #0077AF", fontWeight: "700", width: "127px" }}>Contact us</Button>
                </Link>

                </div>
                <div>
                
                </div>

    </section>
    <div style={{backgroundColor: ""}}>

   
    <section style={{width: "1024px" , "margin": "0 auto", paddingTop: "40px", paddingBottom: "40px"}}>
               

                {/* <Accordion title="How do you guarantee payments to attorneys?" 
                    content="We guarantee attorney payments by us paying attorneys for the work they have done. (i.e. Clients pay us and we pay attorneys) If clients don’t pay us, we still pay attorneys, so attorneys don’t need to worry about not getting paid by clients. We understand hard work and attorney’s hard work will be recognized and paid. "/> */}
                {/* <Accordion title="What happens when clients still don’t pay Juristerra?" 
                    content=""/> */}
                 <Accordion title="What is Juristerra?" 
                    content="Juristerra is a web platform built to support attorneys and clients and to help attorneys work cross jurisdictional.
                    Juristerra was built to help connect clients and attorneys. We strive to solve the problem of the Legal Services Gap. What is the Legal Services Gap? This problem arises when companies and corporations, regardless of those in the US or worldwide, find it difficult to acquire the right attorney or law firm for whatever legal challenge they are facing. Without the proper support and understanding of their own situation and needs, they fall into the trap of hiring a law firm whose expertise and pricing might not align with their management and budget.
                    Juristerra helps to fill this Legal Services Gap by providing Legal Project Management Services and Services Consulting to these companies, helping them to understand their needs better and to connect them with the right attorneys and firms whose expertise align with these needs, and at better prices.
                    Additionally, our online Platform helps attorneys from various firms to both manage their relationships with potential clients and to find and work with other attorneys cross jurisdictionally to solve relevant client needs."/>

                  <Accordion title="What does the Juristerra platform offer client companies?" 
                    content="Clients may use the Juristerra platform to access and retain vetted attorneys and law firms. We work with firms whom we have connected with and checked to make sure they can offer great quality legal work based on their attorneys backgrounds; education, work history, and knowledge of specialties.
                    For the client, this leads to decreased amount of time spent on finding legal help resulting from the increased access to potential attorneys and law firms. Clients may elect to use the Legal Project Management Services and/or the Services Consulting offered by Juristerra to receive help from the platform in finding an attorney or go one step further and retain a project manager to assist in their legal search and legal services management. These programs have been designed to assist management to stay engagement in their legal affairs and do so at lower rates then what the market can directly offer."/>

                  <Accordion title="What does the Juristerra platform offer attorneys and law firms?" 
                    content="Law firms may use the Juristerra platform as a Business Development tool, helping them to reach and attract clients who they otherwise would not be able to reach such as foreign corporations.
                    Additionally, the platform may be used by associates attorneys whose function does not traditionally include looking for new business. This function has been historically reserved for partners through the use of basic networking methods to attract new clients. Through Jursiterra, partners can allow associates to connect with and find new clients, saving time and money for the firm in the long run."/>

                 <Accordion title="What are the Legal Project Management Services / Services Consulting?" 
                    content="Juristerra offers a Legal Project Management Service for client corporations in order to assist them in their legal matters. Many corporations face a 'legal services gap', the inability to find the right legal practitioner who has the right legal background and specialities and furthermore feel lost in the the legal process. In the absence of a dedicated corporate legal team, this process can be very hard and costly.
                    Juristerra's Legal Project Management Services fills in the 'legal services gap' by offering a project manager who can assist companies and corporations in retaining and keeping in touch with their attorney or selected law firm. Our project managers assist companies within the framework of the law (and within State and Country Bar rules) and help to ensure that not only effective communication is maintained to ensure a successful project or outcome, but also to prevent unwanted charges and miscommunication. This service is especially handy for companies and corporations who are looking for native language support (offered through our services) or irrespective of that they just would like someone to help them figure out the legal language of their matters."/>

                  <Accordion title="Who can use these services and how can they get started?" 
                    content="Our Juristerra Legal Project Management Services offer paid plans for companies and corporations who are in need of assistance for their legal needs. The service is open to corporations based in the US and Canada and also in East Asia initially and expanding. If you are located outside of these areas, please contact us for more information and rates.
                    For further information about our plans, please contact us at contact@Juristerra.net."/>

                  <Accordion title="Are these services offered as part of the legal team and do they offer legal advice?" 
                    content="No, our Legal Project Management Services are offered as an auxiliary service to assist the client company and their attorney or law firm with better communication and management of the legal process. Our services operate within the confines of the law and as far as the local Bar associations of the locality allow us to. They are not part of the legal team or the law firm and are not a substitute for an attorneys work, and as such, they do not offer legal advice."/>


                {/* <Accordion title="What kind of payments do you accept?" 
                    content=""/> */}
                  <Accordion title="As an attorney, where does my money go after getting paid?" 
                    content="If you're an attorney helping us with a case for a client, your money will go to your direct deposit."/>
                      <Accordion title="Is Juristerra free to use?" 
                    content="Our platform is currently free to use while in beta. If you plan to use our Legal Project Manager, there is a fee."/>
                     {/* <Accordion title="Are there late fees if client doesn’t pay?" 
                    content="Yes"/> */}
                      {/* <Accordion title="What happens if an attorney claims they didn’t receive a payment and contacts client?" 
                    content=""/>
                      <Accordion title="How will clients know if Juristerra paid the attorney?" 
                    content=""/> */}
              
              
            </section>
            </div>
   
    
  </Layout>
)

export default FAQ;
